import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import KSIMenuCover from '../images/KSIMenuCover.jpg';
import KSIMenu1 from '../images/KSIMenu1.jpg';
import KSIMenu2 from '../images/KSIMenu2.jpg';
import KSIMenu3 from '../images/KSIMenu3.jpg';
import KSIMenu4 from '../images/KSIMenu4.jpg';
import KSIMenu5 from '../images/KSIMenu5.jpg';
import KSIMenu6 from '../images/KSIMenu6.jpg';
import KSIMenu7 from '../images/KSIMenu7.jpg';
import KSIMenu8 from '../images/KSIMenu8.jpg';

const ImageSlider = () => {

  const KSImenu = [
    {
      id: 1,
      picture: KSIMenuCover
    },
    {
      id: 2,
      picture: KSIMenu1
    },
    {
      id: 3,
      picture: KSIMenu2
    },
    {
      id: 4,
      picture: KSIMenu3
    },
    {
      id: 5,
      picture: KSIMenu4
    },
    {
      id: 6,
      picture: KSIMenu5
    },
    {
      id: 7,
      picture: KSIMenu6
    },
    {
      id: 8,
      picture: KSIMenu7
    },
    {
      id: 9,
      picture: KSIMenu8
    }
  ]

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true
        }
      }
    ]
  };

  return (
    <div className="image-slider">
      <Slider {...settings}>
        {KSImenu.map(item => (
          <div key={item.id}>
            <img src={item.picture} alt="menu" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;