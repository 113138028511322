import DocumentTitle from './components/DocumentTitle';
import ImageSlider from './components/ImageSlider';

function App() {
  DocumentTitle("KSI Menu")
  return (
    <div className="App">
      <ImageSlider/>
    </div>
  );
}

export default App;
